@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.britishRight{
    color: var(--main-font-color-dark);
    font-family: var(--roboto-font);
    font-size: 1rem;
    padding-top: 22px;
}

.britishMainVideo h1{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    text-align: center;
    padding: 10px 0;
}

.britishVideo{
    width: 400px;
    height: 210px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}
  
.app-container {
    max-width: 85vw;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: #272727;
    padding: 18px 30px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.479);
    margin-top: 50px;
}

.app-container h1 {
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
    color: var(--background-color);
    font-family: 'Josefin Sans', sans-serif;
    letter-spacing: 2px;
    font-size: 20px;
}

.mp3-text{
    padding: 20px 15px;
    font-size: 14px;
}

.slider-container {
    --progress-bar-height: 4px;
}
  
.slider-container {
    position: relative;
    width: 100%;
}
  
/* Background */
.slider-container::before {
    content: '';
    background-color: white;
    width: 99%;
    height: calc(var(--progress-bar-height) - 1px);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}
  
/* Custom Progress Bar */
.progress-bar-cover {
    background-color: rgb(218, 55, 145);
    width: 0%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
}
  
/*  Hide Original */
.range {
    -webkit-appearance: none;
    background-color: rgba(240, 9, 9, 0.397);
    height: 10px;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
}

.slider-container {
    --thumb-width: 20px;
    --thumb-height: 20px;
}
  
.range::-webkit-slider-thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);
  
    background: #350f2d;
    border: 1px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
}
  
.thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.753);
    z-index: 3;
    background: rgb(255, 255, 255);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none; /* Remove pointer events on thumb so user can click on the actual thumb beaneath it!  */
    user-select: none; /*  Prevent Accidentally highlighting the number while sliding the cursor  */
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    flex-grow: 1;
}
  
.btn-play {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 30px;
    border-color: transparent transparent transparent #ffffff;
}
  
.btn-stop {
    height: 40px;
    width: 30px;
    border-left: 10px solid rgb(255, 255, 255);
    border-right: 10px solid rgb(255, 255, 255);
}

.control-panel {
    padding: 7px 0;
    display: flex;
    justify-content: space-between;
}
  
.timer {
    font-size: 10px;
    font-weight: 200;
    color: rgb(196, 196, 196);
}

.britishImg h1{
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'Josefin Sans', sans-serif;
    letter-spacing: 2px;
    font-size: 20px;
    margin-top: 50px;
}

.britishPic{
    border: 1px solid #2B3648;
    max-width: 85vw;
    margin-left: auto;
    margin-right: auto;
}

.britishSeparator{
    display: flex;
    align-items: center;
    justify-content: center;
}

.britishLine{
    width: 100%;
    max-width: 5.4rem;
    height: 0.1rem;
    background-color: var(--main-font-color-dark);
    position: relative;
}

.britishLine-left::before,
.britishLine-right::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: .5rem solid transparent;
}

.britishLine-right::before{
    border-right-color: var(--main-font-color-dark);
    right: -2px;
}

.britishLine-left::before{
    border-left-color: var(--main-font-color-dark);
    left: 0;
}

.britishAsterisk{
    font-size: 0.5rem;
    color: var(--main-font-color-dark);
    margin: 0 0.6rem;
}

@media screen and (max-width: 450px){
    .britishVideo{
        max-width: 85vw;
    }
}
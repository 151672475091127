@import url('https://fonts.googleapis.com/css2?family=Petemoss&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yuji+Boku&display=swap');

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(206, 12%, 61%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.certificate{
    margin-top: 30px;
    background: #fff;
    width: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px dashed darkslategrey;
    background: #FFFEFA;
}

.certificate h2{
    padding: 10px 0;
    text-align: center;
    font-family: 'Petemoss', cursive;
    font-size: 40px;
    letter-spacing: 4px;
    font-weight: 600;
    color: var(--clr-grey-3);
}

.cSeparator{
    display: flex;
    align-items: center;
    justify-content: center;
}

.cLine{
    width: 100%;
    max-width: 5.4rem;
    height: 0.1rem;
    background-color: var(--main-font-color-dark);
    position: relative;
}

.cLine-left::before,
.cLine-right::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: .3rem solid transparent;
}

.cLine-right::before{
    border-right-color: var(--main-font-color-dark);
    right: 0;
}

.cLine-left::before{
    border-left-color: var(--main-font-color-dark);
    left: 0;
}

.cAsterisk{
    font-size: 0.7rem;
    color: var(--main-font-color-dark);
    margin: 0 0.6rem;
}

.certificateBody{
    padding-top: 45px;
}

.certificateBody h3{
    font-family: 'Architects Daughter', cursive;
    text-align: center;
    font-size: 20px;
    padding-bottom: 15px;
}

.bodyLine{
    width: 100%;
    max-width: 5rem;
    margin: 0 auto;
    height: 0.1rem;
    border: 1px dashed darkslategrey;
}

.certificateText{
    padding: 10px;
    color: rgb(167, 160, 160);
    font-family: 'Yuji Boku', serif;
    font-size: 15px;
}

.certificateLogo{
    width: 25%;
    text-align: center;
    max-width: 20vw;
    margin: 0 auto;
}

.certificateImg{
    padding: 20px 0;
}

.certificateFooter{
    display: flex; 
    justify-content: space-between;
    padding: 8px 10px;
}

.footerLine{
    width: 90px;
    border: 1px dotted rgb(158, 182, 182);
}

.footerText{
    text-align: center;
    font-family: 'Yuji Boku', serif;
    font-size: 14px;
}

.footerBottom{
    text-align: center;
    font-family: 'Yuji Boku', serif;
    font-size: 14px;
}

.footer-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificate-button{
    position: absolute;
    font-size: .8rem;
    letter-spacing: 1px;
    background-color: #1c7bc9;
    padding: 0.3rem 0.4rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;
    bottom: 5px;
}


@media screen and (min-width: 750px){
    .certificate{
        width: 600px;
    }

    .certificate h2{
        font-size: 65px;
    }

    .cLine {
        max-width: 6.4rem;
    }

    .certificateBody h3{
        font-size: 30px;
    }

    .bodyLine {    
        max-width: 10rem;
    }

    .certificateText{
        font-size: 24px;
    }

    .footerText{
        font-size: 20px;
    }

    .footerLine{
        width: 180px;
    }

    .footerBottom{
        font-size: 20px;
    }
}

@media screen and (min-width: 500px){
    .certificate{
        width: 450px;
    }
}

@media screen and (max-width: 330px){
    .certificate{
        margin-top: 60px;
        width: 270px;
    }
}
body{
	margin: 0;
	border: 0;
	font-family: "Roboto", sans-serif;
	color: #4B5563;
	font-size: 16px;
	scroll-behavior: smooth;
}
html {
	scroll-behavior: smooth;
}
  
/* buttons */
.homeDiv .btn:link,
.homeDiv .btn:visited{
	background-color: #1c7bc9;
	text-decoration: none;
	padding: 8px 12px;
	color: #fff;
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	font-weight: 500;
	background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA); 
	border: 1px solid #2563EB;

}
.homeDiv .ctbtn:link,
.homeDiv .ctbtn:visited{
	background-color: #26c7dc ;
	text-decoration: none;
	padding: 8px 12px;
	font-weight: 500;
	display: inline-block;
	color: #fff;
	border-radius: 20px;
}
.homeDiv .btn-small:link,
.homeDiv .btn-small:visited{
	align-self: center;
	/* margin-left: 20px; */
}
.homeDiv .btn-big:link,
.homeDiv .btn-big:visited{
	margin: auto;
}


/* icons */
.homeDiv .icons{
	width: 24px;
	height: 24px;
	display: inline;
	margin-right: 10px;
}

.homeDiv .logo-sm{
	display: inline;
	padding: 5px;
	width: 36px;
	height: 36px;
}

/*icons*/

/* page1 */
.homeDiv #page1{
	width: 100%;
	height: auto;
	background-color: #EFF6FF;
	padding-bottom: 100px;
}
.homeDiv #container1{
	width: 100%;
	height: auto;
	background-color: #EFF6FF;
}
.homeDiv #header{
    background-color: #E5E7EB;
    position: fixed;
    box-shadow: 5px 3px 7px #6b7280;
    width: 100%;
    z-index: 999;
}
.homeDiv #menu{
	max-width: 1180px;
    margin: 0px auto;
    width: auto;
	height: 70px;
	background-color: inherit;
	display: flex;
	padding: 12px 8px;
}
.homeDiv #logo{
	width: 30%;
	background-color: inherit;
	color: white;
	font-size: 24px;
}
.homeDiv .logoimg{
	width: auto;
	border-radius: 10px;
	display: flex;
}
.homeDiv #menubtn{
	width: 60%;
	background-color: inherit;
}
.homeDiv #hamburger{
	cursor: pointer;
	width: 10%;
	background-color: inherit;
	color: rgb(8, 9, 94);
	font-size: 24px;
	display: flex;
    flex-direction: row-reverse;
	padding-right: 10px;
}
.homeDiv #hamburger :active{
	color: #10B981;
}
.homeDiv #content1{
	padding: 90px 10px 10px 10px;
}
.homeDiv #content1 h1{
	font-size: 22px;
	font-weight: 600;
}
.homeDiv #content1 p{
	margin-top: 20px;
	line-height: 26px;
	font-size: 18px;
	font-weight: 500;
}
.homeDiv #button-explore {
	margin-top: -25px;
    margin-left: -3px;
}
.homeDiv .leftright-ctn {
	margin-left: -10px;
    background: #f7f7f7;
    box-shadow: 3px 3px 7px grey;
    opacity: 0.5;
}
.homeDiv .whyList{
	margin: 10px;
	list-style-type: disc;
	font-weight: 600;
	font-size : 14px
}

.homeDiv .whyList li{
	padding-bottom: 12px;
	list-style-type: disc;
}

/* page1 */

/*page2*/

.homeDiv #page2{
	height: auto;
	background-color: #FEF2F2;
	padding-top: 35px;
	padding-bottom: 100px;
}
.homeDiv #page-mini{
	display: flex;
	overflow: hidden;
	overflow-x: scroll;
	align-items: center;
	background-color:  #FEF2F2;
	height: auto;
}
.homeDiv .example::-webkit-scrollbar {
	display: none;
}


.homeDiv .example {
	-ms-overflow-style: none;  
	scrollbar-width: none;  
}

.homeDiv #photo-container-big::-webkit-scrollbar {
	display: none;
}

.homeDiv #photo-container-big {
	-ms-overflow-style: none;  
	scrollbar-width: none;  
}
.homeDiv #caption2{
	margin-top: 40px;
	padding: 20px 5px; 
	color: black;
	background-color: #FEF2F2;
}
.homeDiv #caption2 h2{
	font-size: 20px;
    font-weight: 500;
	padding: 10px;
	text-align: center;
}
.homeDiv #c2h2container{
	border: 2px solid #FCA5A5;
	border-radius: 10px;
	background-color: #fff;
}
.homeDiv .container{
	width: 280px; 
	height: 340px;
	background-color: #FECACA;
	display: flex;
	flex-direction: column;
	box-shadow: 5px 5px 7px gray;
	margin: 10px 20px;
	border-radius: 30px;
	padding-top: 20px;
}

.homeDiv .imgtxt{
	width: auto;
	height: auto;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	background-color: #FECACA;
}
/* .homeDiv .left{
	width: 50%;
}
.homeDiv .left h2{
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 600;
} */
/* .homeDiv .right{
	width: 50%;
	padding-top: 10px;
	font-size: 14px;
	line-height: 2;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
} */

.homeDiv .imgtxt img{
	border-radius: 10px;
}
.homeDiv .txt{
	width: 280px;
	display: flex;
	padding-right: 20px;
	justify-content: center;
}
/*page2*/

/*page3*/
.homeDiv #p3h2Container{
	border: 2px solid #2563EB;
	border-radius: 10px;
	background-color: #fff;
}

.homeDiv #p3h2{
	padding: 10px;
	color: #1F2937 ;
	font-size: 20px;
	font-weight: 500;
	text-align: center;
}
.homeDiv #page3{
	width: 100%;
	height: auto;
	background-color: #BFDBFE;
	display: flex;
	flex-direction: column;
	padding-bottom: 100px;
}
.homeDiv video{
	border-radius: 20px;
}
.homeDiv #container3{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	margin-top: 40px;
}
.homeDiv .video-container{
	padding: 20px;
	margin-top: 20px;
}
/*page3*/


/*PAGE 4*/
.homeDiv #page4{
	width: 100%;
	height: auto;
	background-color: #F9FAFB;
	display: flex;
	justify-content: center;
	padding-bottom: 100px;
}
.start-learnbtn {
	padding: 5px !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	margin-top: 5px !important;	
}
.homeDiv #container4{
	width: 90%;
	height: auto;
	padding: 50px 0px;
}
.homeDiv #container4 h1{
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 20px;
}

/*page4*/
/* Page5 */
.homeDiv #page5{
	max-width: 1180px;
    height: auto;
    display: flex;
    width: auto;
    margin: 0px auto;
}
.homeDiv #container5{
	width: 100%;
	height: auto;
}
.homeDiv #photo-container-big{
	width: 100%;
	height: 220px;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	overflow-x: scroll;
	border-radius: 20px;
}
.homeDiv .photoContainer{
	display: none;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 5px;
	border-radius: 20px;
	flex-shrink: 0;
}
.homeDiv .photo-mini{
	width: 100%;
	height: 100%;
	background-color: #fff;
	border-radius: 20px;
}
.homeDiv #page5 h2{
	padding-top: 15px;
	font-size: 20px;
	font-weight: 700;
}
.homeDiv #btnDiv{
	display: flex;
	justify-content: center;
	margin-top: -20px;
	margin-bottom: 30px;
}
.homeDiv #next{
	cursor: pointer;
}
.homeDiv #prev{
	cursor: pointer;
}
.homeDiv #next:active{
	background-color: rgb(173, 255, 47);
}
.homeDiv #prev:active{
	background-color: rgb(173, 255, 47);
}
.homeDiv #btnDiv span{
	font-size: 26px;
}
.homeDiv .btnNp{
	font-size: 16px;
	width: auto;
	height: auto;
	background-color: #60A5FA;
	border-radius: 10px;
	text-align: center;
	padding: 2px 10px;
	margin-right: 20px;
}


/*footer*/
/* .homeDiv footer{
	width: 100%;
	height: 1000px;
	background-color:  #33658a;
	display: flex;
	justify-content: center;
}
.homeDiv #f-container{
	width: 90%;
	height: auto;
}
.homeDiv #f-logo{
	color: #fff;
	font-size: 24px;
	text-align: center;
	padding-bottom: 30px;
	font-weight: 700;
	margin-top: 20px;
}
.homeDiv #f-link-container{
	padding: 12px;
}
.homeDiv .f-link{
	text-decoration: none;
	color: #fff;
	font-weight: 500;
}
.homeDiv #copyR{
	color: #fff;
	font-size: 12px;
	margin-top: 50px;
	text-align: center;
}
.homeDiv #f-app span{
	color: #fff;
	margin-top: 20px;
}
.homeDiv #f-app img{
	margin-top: 10px;
}
.homeDiv #logofooter{
	width: 30%;
	height: 120px;
	fill: blue;
	border-radius: 10px;
} */
/*footer*/

/***********       NAV     ********/
.homeDiv #nav-menu{
	width: 100%;
	height: 70px;
	background-color: #E5E7EB;
	box-shadow: 5px 3px 7px #4b4a4a;
	display: flex;
}
.homeDiv #navContainer{
	width: 320px;
	height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0px 10px;
}
#nav {
	margin-left: -320px;
}
.logoimg1 {
	width: 180px;
	display: flex;
}
.homeDiv #nav-img{
	width: 65px;
	height: 100%;
	padding: 10px 2px;
}
.homeDiv #nav-img img{
	width: auto;
	height: 90%;
}
.homeDiv #closebtndiv{
	cursor: pointer;
	width: 50px;
	padding: 0px 10px;
	text-align: right;
	justify-content: flex-end;
}
.homeDiv #closebtn{
	font-size: 48px;
	font-weight: 800;
	color: steelblue;
}
.homeDiv #closebtndiv :active {
	color: #F87171;
}
.homeDiv #nav-content{
	padding: 10px 12px;
	display: flex;
	flex-direction: column;
	width: 320px;
}
.homeDiv .overlayItems{
	max-width: 300px;
	cursor: pointer;
	display: flex;
	text-decoration: none;
	align-items: center;
	padding: 5px;
	border: 1px solid #DBEAFE;
	background-color: #EFF6FF;
	border-radius: 16px;
	margin-bottom: 8px;
	box-shadow: 2px 1px 5px #7b7f86;
} 
.homeDiv .overlayItems:active{
	background-color: #D1FAE5;
}
.homeDiv .overlayItems span{
	font-size: 18px;
	font-weight: 600;
}
.homeDiv #overlayItemsContainer{
	display: block;
	transition: 0.5s;
	margin-top: 10px;
}
.homeDiv .navIcon{
	height: 100%;
	width: 36px;
	height: auto;
	display: inline;
	margin-right: 20px;
	opacity: 1.5;
}
.homeDiv .course{
	opacity: 0.8;
}
.homeDiv #navVersion{
	height: 70px;
	font-size: 10px;
	font-weight: 800;
	display: flex;
	align-items: flex-end;
}
.homeDiv .overlay{
	width: 320px;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0; 
	background-color: #BFDBFE;
	z-index: 1;
	transition: 0.5s;
	overflow-x: hidden;
	color :#5e646e;
	transition-timing-function: ease;
}
.homeDiv .overlayPM{
	cursor: pointer;
	width: 100%;
	max-width: 320px;
	height: 65px;
	background-color: #6d96c7;
	border-radius: 2px;
	padding: 5px 10px;
	margin: 1px;
	display: flex;
	gap: 20px;
	border-radius: 15px;
	align-items: center;
}
.homeDiv #avatar{
	width: 50px;
}
.homeDiv .avatarpp{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #fff;
	border:3px solid rgb(248, 212, 6);
}
.homeDiv #profileText{
	color: #fff;
	font-weight: 600;
	width: 80%;
}
.homeDiv .menuContainer{
	display: flex;
	width: 40px;
	height: 40px;
	background-color: white;
}
.homeDiv #menupp{
	width: 20%;
}
.homeDiv #menuText{
	width: 80%;
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	display: flex;
	align-items: center;
}
#page1textContainer:before {
	opacity: 0.25;
}
@media screen and (min-width: 500px){
	.homeDiv #photo-container-big {
		height: 380px;
	}
	.homeDiv #prev{
		margin-top: 180px;
	}
	.homeDiv #next{
		margin-top: 180px;
	}
	.homeDiv .whyList{
		line-height: 2;
	}
	#page1textContainer {
		padding-top: 10px;
		padding-left: 10px;
		padding-bottom: 5px;
	}
	.homeDiv #content1 h1 {
		font-size: 26px;
	}
	.homeDiv .whyList {
		margin: 24px;
		font-size: 18px;
	}
}
@media screen and (min-width: 900px){
	.homeDiv #photo-container-big {
		height: 400px;
	}
	.homeDiv #prev{
		margin-top: 180px;
	}
	.homeDiv #next{
		margin-top: 180px;
	}
}
@media screen and (min-height: 812px){
	.homeDiv #page5{
		margin-bottom: 30px;
		margin-top: 30px;
	}
	/*page3*/	
	.homeDiv .video-container {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	/* .homeDiv #container3{
		margin-top: 60px;
	} */
}
@media screen and (min-height: 1024px){
	.homeDiv #prev{
		margin-top: 150px;
	}
	.homeDiv #next{
		margin-top: 150px;
	}
	.homeDiv #photo-container-big {
		height: 400px;
	}
	.homeDiv #logo {
		width: 20%;
	}
	.homeDiv #menubtn {
		width: 80%;
	}
	.homeDiv #content1 h1 {
		font-size: 30px;
	}
	.homeDiv #content1 p {
		font-size: 22px;
	}
	.homeDiv #page1textContainer {
		padding: 20px;
	}
	.homeDiv .btn-small:link,
	.homeDiv .btn-small:visited {
		margin-left: 20px;
	}
	/*page2*/
	.homeDiv #caption2{
		margin-top: 20px;
	}
	.homeDiv #page-mini{
		margin-bottom: 50px;
	}
	/*page3*/
	.homeDiv video {
		width: auto;		
	}
	.homeDiv #p3h2 {
		font-size: 30px;
	}
}
@media screen and (min-height: 1366px){
	.homeDiv #prev{
		margin-top: 180px;
	}
	.homeDiv #next{
		margin-top: 180px;
	}
	.homeDiv #photo-container-big {
		height: 450px;
	}
	.homeDiv #content1 h1 {
		font-size: 36px;
	}
	.homeDiv #content1 p {
		font-size: 28px;
	}
	/*page2*/
	.homeDiv #caption2 h2{
		font-size: 30px;
	}
	.homeDiv #page-mini{
		flex-wrap: wrap;
		justify-content: center;
	}
	/*page3*/
	.homeDiv video {
		width: auto;		
	}
}
@media screen and (min-width: 1024px) {
	.homeDiv .whyList{
		line-height: 2;
		margin-left: 30px;
	}
	.homeDiv #photo-container-big {
		height: 420px;
	}
	.homeDiv #page3{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.homeDiv #page-mini{
		flex-wrap: wrap;
		justify-content: center;
	}
	.homeDiv .container{
		margin: 20px 30px;
	}
	.homeDiv video {
		width: auto;		
	}
	.homeDiv #videoDiv{
		display: flex;
	}
	.homeDiv .video-container{
		margin: 20px;
	}
}

.navbar__toggle{
	font-size: 25px;
    font-weight: 500;
	transition: all 0.3s ease-in-out;
	color: #2B3648;
	cursor: pointer;
}
.navbar__toggle:hover{
	color: #EFF6FF;
}

.Spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.75);
    top: 0;
    left: 0;
    z-index: 1000;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.examinationHeader{
    padding: 100px 15px 0 15px;
}

.examinationHeader h1{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 28px;
    text-align: center;
}

.examinationBox{
    min-width: 85vw;
    padding:8px;
    background:#fff;
    border-radius:10px;
    box-sizing:border-box;
    z-index:2;
    border: 1px solid var(--main-font-color-dark);
}

.examinationBox h2{
    text-align: center;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 20px;
    color: darkslategrey;
    font-family: 'Raleway', sans-serif;
}

.ex-select{
    margin-top: 40px;
}

.ex-select h1{
    font-size: 28px;
}

.ex-container {
    max-width: 85vw;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: #272727;
    padding: 18px 30px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.479);
    margin-top: 20px;
}

.ex-container h1 {
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
    color: var(--background-color);
    font-family: 'Josefin Sans', sans-serif;
    letter-spacing: 2px;
    font-size: 20px;
}

.ex-img{
    width: 130px;
    height: 130px;
    object-fit: cover;
}

.input-hidden{
    position: absolute;
    left: -99px;
}

.ex-answer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 10px;
}

.ex-answer div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
}

input[type=radio]:checked + label>img {
    border: 1px solid #fff;
    box-shadow: 0 0 3px 3px #090;
}

input[type=radio] + label>img {
    border: 1px dashed #444;
    width: 300px;
    height: 300px;
    transition: 500ms all;
}

input[type=radio]:checked + label>img {
    transform: 
       scale(1.15);
}

@media screen and (max-width: 700px){
    input[type=radio] + label>img{
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width: 600px){
    input[type=radio] + label>img{
        width: 200px;
        height: 200px;
    }

    .ex-select h1{
        font-size: 20px;
    }

    .examinationHeader h1{
        font-size: 20px;
    }
}

@media screen and (max-width: 500px){
    input[type=radio] + label>img{
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 365px){
    input[type=radio] + label>img{
        width: 130px;
        height: 130px;
    }
}

@media screen and (max-width: 310px){
    .ex-answer{
        grid-template-columns: 1fr;
    }

    input[type=radio] + label>img{
        width: 150px;
        height: 150px;
    }
}
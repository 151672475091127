@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.teachingGap{
    padding-top: 3.9rem;
}

.teachingHeader{
    display: grid;
	grid-template-columns: 1fr 2fr;
    margin: 0 auto;
    max-width: 1170px;
    gap: 0 2rem;
}

.teachingLine{
    border: 1px solid var(--main-font-color-dark);
    width: 100%;
}

.teachingRight{
    color: var(--main-font-color-dark);
    font-family: var(--roboto-font);
    font-size: 1rem;
}

.teachingLeft{
    padding-top: 1rem;
}

.teachingLeftHeader{
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    margin: 18px auto;
    font-family: var(--roboto-font);
}

.teachingImgAndText img{
    border: 1px solid var(--main-font-color-dark);
}

.teachingImgAndText p{
    color: var(--clr-grey-3);
    font-family: var(--roboto-font);
    padding: 10px 10px;
}

.teachingMessage{
    box-shadow: 20px 20px 50px rgb(0 0 0 / 50%);
    max-width: 450px;
    border-radius: 3px;
    padding: 14px;
    margin: 35px auto;
    background: rgba(252, 250, 242, 0.1);
    border-top: 2px solid #4EA500;
    text-align: center;
}

.teachingMessage p{
    padding-top: 14px;
    color: var(--clr-grey-3);
    font-family: var(--roboto-font);
    font-size: .9rem;
}

.teachingCon{
    background-color: #4CA500;
    padding: 8px;
    border-radius: 25px;
    color: var(--background-color);
    letter-spacing: .1rem;
    font-family: var(--roboto-font);
    margin-bottom: 8px;
}

.teachingBtn{
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 18px;
}

.detailBtn{
    font-size: .8rem;
    background-color: var(--main-font-color-dark);
    padding: .6rem .5rem;
    color: var(--background-color);
    border-radius: .4rem;
    transition: .5s;
    font-weight: 600;
    border: 1px solid var(--main-font-color-dark);
    font-family: var(--roboto-font);
    letter-spacing: 1px;
}

.takeBtn{
    font-size: .8rem;
    letter-spacing: 1px;
    background-color: #1c7bc9;
    padding: .6rem .5rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;
}

.teachingBr{
    margin-bottom: 9rem;
}

.fontAwesomeTeach{
    font-size: 1.2rem;
    color: var(--background-color);
}

.teachingNavGap{
    padding-top: 30px;
}
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Alata&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Alata&family=Prompt&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cus-nav-link {
  @apply inline-block cursor-pointer px-8 py-4 transition-colors hover:bg-gray-500 hover:text-white hover:no-underline duration-300;
}

.active-nav {
  @apply bg-gray-500;
}

.popup-box {
  @apply opacity-100 absolute shadow-lg z-50 bg-white mt-20 p-4 rounded-md;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.popup-cover {
  @apply w-full bg-gray-500 h-screen h-full opacity-50 absolute z-40 flex;
}

.green-btn {
  @apply bg-green-600 p-2 rounded text-lg text-gray-100 mr-2;
}

.red-btn {
  @apply bg-red-600 p-2 rounded text-lg text-gray-100;
}

.hidden-tran {
  @apply invisible delay-1000 ease-in duration-1000 transition-all;
}
#code-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#code-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.profile-name:hover .profile-sub {
  display: block;
}
.profile-sub:hover .profile-sub {
  display: block;
}
#tooltip-2body {
  opacity: 0;  
  transition: 0.5s;
}
#tooltip-1body {
  opacity: 0;
  right: -135px;
  transition: 0.5s;
}
#tooltip-1ctr:hover + .tooltip-1 {
  opacity: 1;
}
.tooltip1-icon {
  margin-top: 30px;
  position: absolute;
  top: -45px;
  color: #343a40;
}

.tw-active {
  @apply text-blue-500 border-blue-500 border-b-2 !important;
}
.tab-button {
  @apply text-gray-600 mr-1 py-1 px-4 block hover:text-blue-500 focus:outline-none border-gray-300 border-b-2;  
}
.rotate-ani {
  animation-name: spinning;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-direction: normal;
}
.rotate-ani-back {
  animation-name: spinning-back;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-direction: normal;
}
.div-slide {
  transition: 1000ms;
}
.tab-slider::-webkit-scrollbar {
  display: none;
}
.tab-slider {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.topic-detail-container img {
  width: 100% !important;
  margin-top: 10px;
}

#style-3 {
  margin-right: -10px;
  width: 101%;
}
#style-3::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
  width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
  background-color: #999;
}

.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
}
.abt-course-setup {
  @apply flex flex-col;
}
.nextbtn {
  @apply p-2 bg-green-300 w-24 ml-1 mb-2;
}
.backbtn {
  @apply p-2 bg-yellow-300 w-24 mr-1 mb-2;
}

.ofs-fadeout-ani { 
    display: flex !important;
    animation-name: fadeout;
    animation-duration: 500ms;
    animation-fill-mode: forwards;    
}

.ofs-fadein-ani { 
    animation-name: fadein;
    animation-duration: 500ms;
    animation-fill-mode: forwards;    
}
@keyframes fadeout {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadein {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@media (min-width: 1025px) {
  .cus-container {
    max-width: 100%;
  }
}

@media (min-width: 1280px) {
  .cus-container {
    max-width: 1180px;
  }
}
 
@media (min-width: 1420px) {
  .navbar-brand {
    margin-left: -90px !important;
  }
}

@media (min-width: 1536px) {
  .cus-container {
    max-width: 1300px;
  }
}
@keyframes spinning {
  100% { transform: rotate(180deg); }
}
@keyframes spinning-back {
  0% { transform: rotate(180deg); }
  100% { transform: rotate(0deg); }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.loginMain{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background-color);
}

.loginContainer{  
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loginContainer .loginCard{
    position: relative;
    width: 380px;
    border-radius: 15px;
    padding: 18px;
}

.loginContainer .loginCard > div > h2{
    font-family: var(--roboto-font);
    font-size: 1.8em;
    color: #161623;
    padding-bottom: 30px;
    font-weight: 600;
    letter-spacing: 2px;
}

label{
    margin-bottom: 0px;
}

.loginEmail{
    font-family: var(--roboto-font);
    color: #161623;
    letter-spacing: 1px;
    font-size: 14px;
}

.regLoginInput {
	width: 100%;
	padding: 4px;
	font-size: 14px;
    border-bottom: 1px solid var(--main-font-color-dark);
	outline: none;
	background: transparent;
}

.loginPassFor{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 12px 0 5px 0;
}

.loginPass{
    font-family: var(--roboto-font);
    color: #161623;
    letter-spacing: 1px;
    font-size: 14px;
}

.loginForPass{
    font-family: var(--roboto-font);
    color: #161623;
    font-size: 12px;
}

.loginBtn{
    background: #46a6f5;
	border: 2px solid #46a6f5;
	border-radius: 4px;
	color: var(--background-color);
	display: block;
	font-family: var(--roboto-font);
	font-size: 14px;
	padding: 4px;
	width: 100%;
    margin: 16px 0;
}

.loginAccSign{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.loginAcc{
    font-size: 14px;
    color: #161623;
    font-family: var(--roboto-font);
}

.loginSign{
    font-size: 12px;
    color: #161623;
    font-family: var(--roboto-font);
}

.RegisterForm{
    margin: 18px 0;
}

@media screen and (max-width: 400px){
    .loginContainer .loginCard{
        width: 300px;
    }
}

@media screen and (max-width: 300px){
    .loginContainer .loginCard{
        width: 270px;
    }
}
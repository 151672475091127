@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

body{
    background-color: var(--background-color);
}

.main-containerTopic{
    background-color: var(--main-font-color-dark);
    padding-top: 70px;
}

.headerTopic{
    padding: 30px 90px;
    display: grid;
	grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

.imgAndText img{
    border: 1px solid var(--background-color);
    border-radius: 5px;
}

.imgAndText p{
    margin-top: 12px;
    color: var(--background-color);
    font-family: var(--roboto-font);
}

.topicLink{
    color: var(--background-color);
    font-family: var(--roboto-font);
    font-size: 1rem;
}

.greaterThan{
    color: #C0C0C0;
}

.topicLine{
    border: 1px solid #C0C0C0;
    width: 100%;
    margin: 8px 0;
}

.topicHeader{
    color: var(--background-color);
    margin-bottom: 8px;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    margin: 18px auto;
    font-family: var(--roboto-font);
}

.btn{
    margin-top: 1rem;
}

.cta-btn{
    font-size: .8rem;
    letter-spacing: 1px;
    background-color: #1c7bc9;
    padding: .5rem .6rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;
}

.re-btn{
    font-size: .8rem;
    color: var(--background-color);
    background-color: var(--main-font-color-dark);
    letter-spacing: 1px;
    padding: .5rem .4rem;
    border-radius: .4rem;
    transition: .5s;
    font-weight: 600;
    border: 1px solid var(--main-font-color-dark);
}

.tak-btn{
    font-size: .8rem;
    letter-spacing: 1px;
    background-color: #1c7bc9;
    padding: .5rem .4rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;
}

.reviewButton{
    background-color: #F5B800;
    border: none;
    color: var(--main-font-color-dark);
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin-top: 10px;
    border-radius: 15px;
    font-weight: 600;
    border: 1px solid #ccc;
    box-shadow: 0 0 8px #ccc;
}

.reviewButton:hover{
    color: var(--background-color);
    transition: all 0.5s ease;
}

.reviewButton:focus {
    outline:0;
}

.syllabusesHeader{
    font-size: 27px;
    text-align: center;
    padding-top: 35px;
    color: var(--color-gold);
    font-family: 'Herr Von Muellerhoff', cursive;
}

.separator{
    display: flex;
    align-items: center;
    justify-content: center;
}

.line{
    width: 100%;
    max-width: 5.4rem;
    height: 0.2rem;
    background-color: var(--main-font-color-dark);
    position: relative;
}

.line-left::before,
.line-right::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: .5rem solid transparent;
}

.line-right::before{
    border-right-color: var(--main-font-color-dark);
    right: 0;
}

.line-left::before{
    border-left-color: var(--main-font-color-dark);
    left: 0;
}

.asterisk{
    font-size: 0.7rem;
    color: var(--main-font-color-dark);
    margin: 0 0.6rem;
}

.topicTeaching{
    font-size: .9rem;
    color: var(--main-font-color-dark);
}

.review{
    left: 0;
}

.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px 0;
    padding: 0 20px;
}

.accordion{
    width: 500px;
}

.item{
    background-color: #f0ebe1;
    margin-bottom: 5px;
    padding: 10px 20px;
}

.title{
    color: #85662b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.content{
    color: #8b7f75;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

.TeachingQuestion{
    padding-top: 20px;
}

.comAndQA{
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 1rem;
    align-items: center;
}

.progress{
    flex-grow: 1;
    height: 1rem;
    overflow: hidden;
    background-color: var(--background-color);
}

.progress-line{
    height: 100%;
    width: 0%;
    transition: all 0.4s ease-in;
    background-color: var(--color-yellow);
}

.reviewTake{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1000px){
    .headerTopic{
        grid-template-columns: 1fr;
        padding: 30px 20px;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.userQuestionHeader{
    margin: 16px 0px;
    display: flex;
    justify-content: center;
    font-family: var(--roboto-font);
    font-size: 20px;
    color: var(--main-font-color-dark);
    font-weight: 600;
}

.userQuestionMain{
    padding: 100px 15px 0 15px;
}

.time-circle{
    width: 40%;
    max-width: 20rem;
    position: relative;
    display: grid;
    margin: 12px auto;
}

.time-circle::before{
    border: 3px solid #fafafa;
    border-radius: 100%;
    animation: 1s linear infinite alternate abberation, 4s linear infinite spin;
    padding-top: 100%;
    content: "";
    position: relative;
    pointer-events: none;
    z-index: 2;
}

.time-circle.paused::before{
    animation: none;
    transition: 0.3s transform;
}


@keyframes abberation {
    from {
      box-shadow: 0 0 0 0 #CB2918, 0 0 0 0 #56719A;
    }
    to {
      box-shadow: 0.25rem 0.125rem 0 0 #CB2918, -0.25rem -0.125rem 0 0 #56719A;
    }
}
  
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.time {
    font-size: 1rem;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.questionDetailMain{
    min-width: 85vw;
    padding:8px;
    background:#fff;
    border-radius:10px;
    box-sizing:border-box;
    z-index:2;
    border: 1px solid var(--main-font-color-dark);
}

.questinHeader{
    font-size: 18px;
    font-family: var(--roboto-font);
    font-weight: 600;
} 

.questinAndAns p{
    color: var(--main-font-color-dark);
    font-size: 15px;
    font-family: var(--roboto-font);
}

.questinAndAns{
    display: flex;
    align-items: baseline;
    margin-bottom: 14px;
}

.totalTotalTime{
    display: flex;
}

.totalTime{
    font-family: var(--roboto-font);
    font-weight: 600;
    font-size: 18px;
}

.ttNumber{
    font-size: 18px;
}

.userQuestionSuccess{
    box-shadow: 10px 10px 20px rgb(0 0 0 / 50%);
    max-width: 450px;
    border-radius: 3px;
    padding: 14px;
    margin: 35px auto;
    border-top: 2px solid #4EA500;
    font-family: var(--roboto-font);
    text-align: center;
    background-color: #fff;
}

.option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 13.33333px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 25px;
    width: 25px;
    transition: all 0.15s ease-out 0s;
    background: #cbd1d8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
}

.option-input:hover {
    background: #9faab7;
}

.option-input:checked {
    background: #40e0d0;
}

.option-input:checked::before {
    height: 25px;
    width: 25px;
    position: absolute;
    content: '✔';
    display: inline-block;
    font-size: 18.66667px;
    text-align: center;
    line-height: 25px;
}

.option-input:checked::after {
    -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: #40e0d0;
    content: '';
    display: block;
    position: relative;
    z-index: 100;
}

.option-input.radio {
    border-radius: 50%;
}

.option-input.radio::after {
    border-radius: 50%;
}

@keyframes click-wave {
    0% {
      height: 10px;
      width: 10px;
      opacity: 0.35;
      position: relative;
    }

    100% {
      height: 200px;
      width: 200px;
      margin-left: -80px;
      margin-top: -80px;
      opacity: 0;
    }
}

@media screen and (max-width: 1024px) {
    .time-circle{
        width: 30%;
    }

    .startTime{
        font-size: 34px;
    }

    .totalTime{
        font-size: 18px;
    }
    
    .ttNumber{
        font-size: 18px;
    }

    .userQuestionHeader{
        font-size: 45px;
    }

    .userQuestionSuccess{
        max-width: 600px;
        font-size: 24px;
    }

    .totalTotalTime{
        font-size: 24px;
    }
}

@media screen and (max-width: 1010px) {
    .userQuestionHeader{
        font-size: 25px;
    }

    .userQuestionSuccess{
        max-width: 600px;
        font-size: 24px;
    }
}

@media screen and (max-width: 550px) {
    .time-circle{
        width: 30%;
    }

    .ttNumber{
        font-size: 16px;
    }

    .userQuestionSuccess{
        font-size: 20px;
    }

    .totalTotalTime{
        font-size: 16px;
    }
}

@media screen and (max-width: 420px) {
    .userQuestionSuccess{
        font-size: 15px;
    }

    .time-circle{
        width: 38%;
    }    
}

@media screen and (max-width: 350px){
    .totalTotalTime{
        font-size: 12px;
    }

    .userQuestionSuccess{
        font-size: 14px;
    }
}

@media screen and (max-width: 300px){
    .time-circle{
        width: 45%;
    }
}
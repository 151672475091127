@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.editMain{
    background-color: var(--main-font-color-dark);
    width: 100%;
    padding-top: 20px;
}

.editPadding{
    max-width: 70%;
    margin: 0px auto;
    padding: 35px 0;
}

.editHeader{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.borderProfile{
    width: 250px;
    height: 250px;
    border: 4px solid transparent;
    border-radius: 50%;
    background-image: linear-gradient(#fff, #fff), linear-gradient(40deg, #f43d3d, #FCFAF2);
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: relative;
    overflow: hidden;
}

.profilePic{
    position: absolute;
    top: 14%;
    left: 12%;
    font-size: 210px;
    color: var(--main-font-color-dark);
}

.editTopBottomgap{
    display: flex;
    flex-direction:column;
    gap: 5rem 0;
}

.editEmailAndName{
    display: flex;
    justify-content: space-around;
    gap: 2rem;
}

.inforHeader{
    font-family: var(--roboto-font);
    color: #c1bfbf;
    font-size: 14px;
    letter-spacing: 2px;
    padding-bottom: 8px;
}

.editName{
    font-family: var(--roboto-font);
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
}

.nameAwesome{
    display: flex;
    align-items: center;
    color: #fff;
    gap: .5rem;
}

.editNameText{
    outline: none;
    position: relative;
    border-width: 0 0 2px;
    background: inherit;
    padding-left: 2px;
    border-color: #fff;
    color: #fff;
    width: 170px;
}

.awesomeEdit{
    position: absolute;
    margin-left: -20px;
    color: var(--background-color);
}

.point{
    font-family: var(--roboto-font);
    color: #c1bfbf;
    font-size: 14px;
    letter-spacing: 2px;
}

.editChoose{
    padding: 28px 0;
}

.radioTextHeader{
    display: flex;
    gap: 2rem;
}

.radioText{
    display: flex;
    align-items:baseline;
    gap: .4rem;
}

.radioText > p{
    font-family: var(--roboto-font);
    letter-spacing: 1px;
    color: #fff;
}

.editBtn{
    display: flex;
    justify-content: space-around;
    gap: 1.6rem;
}

.editUpAndRe{
    font-size: .8rem;
    letter-spacing: 2px;
    background-color: #1c7bc9;
    padding: .6rem 1.6rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;

}

.second{
    background: var(--background-color);
}

.studayingHeader{
    font-size: 27px;
    text-align: center;
    padding-top: 35px;
    color: var(--color-gold);
    font-family: 'Herr Von Muellerhoff', cursive;
}

.separators{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
}

.lines{
    width: 100%;
    max-width: 8.4rem;
    height: 0.2rem;
    background-color: var(--main-font-color-dark);
    position: relative;
}

.line-lefts::before,
.line-rights::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: .5rem solid transparent;
}

.line-rights::before{
    border-right-color: var(--main-font-color-dark);
    right: 0;
}

.line-lefts::before{
    border-left-color: var(--main-font-color-dark);
    left: 0;
} 

.asterisks{
    font-size: 0.7rem;
    color: var(--main-font-color-dark);
    margin: 0 0.6rem;
}

.courseAndCompletement{
    font-family: var(--roboto-font);
    letter-spacing: 1px;
    padding: 16px 5px;
    font-weight: 600;
    font-size: 18px;
}

.editTable{
    padding: 18px 0 18px 6px;
    font-family: var(--roboto-font);
}

.editProgress{
    flex-grow: 1;
    height: .6rem;
    overflow: hidden;
    background-color: #e8f2f9;
    border-radius: 20px;
}

.progress-lines{
    height: 100%;
    width: 0%;
    transition: all 0.4s ease-in;
    background-color: var(--color-yellow);
}

.editPercentProgress{
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 1rem;
}

.editPer{
    color: #2b98e5;
    font-size: 14px;
}

.editMainTable{
    max-width: 70%;
    margin: 25px auto;
}

.editSecondTable{
    width: 100%;
}

.courseTitle{
    font-family: var(--roboto-font);
    color: var(--main-font-color-dark);
    font-weight: 600;
    font-size: 14px;
}

.courseSyllabus{
    font-family: var(--roboto-font);
    color: gray;
    font-size: 14px;
}

.progressBarPer{
    font-size:9px;
}

@media screen and (max-width: 1025px){
    .editPadding{
        max-width: 90%;
    }

    .editChoose{
        padding: 28px 0;
    }
}

@media screen and (max-width: 780px){
    .editPadding{
        max-width: 95%;
    }

    .editHeader{
        grid-template-columns: 1fr;   
        justify-items: center;
    }

    .borderProfile{
        margin-bottom: 40px;
        justify-content: center;
    }

    .editEmailAndName{
        justify-content: space-between;
    }

    .editChoose{
        padding: 28px 0;
    }

    .editTopBottomgap{
        gap: 2rem 0;
    }

    .editBtn{
        justify-content: space-between;
    }
}

@media screen and (max-width: 650px){
    .editMainTable{
        max-width: 90%;
    }

    .editPercentProgress{
        display: grid;
        text-align: center;
        gap: 0;
    }
}

@media screen and (max-width: 500px){
    .borderProfile{
        width: 200px;
        height: 200px;
    }

    .profilePic{
        font-size: 166px;
    }

    .editEmailAndName{
        justify-items: center;
        justify-content: space-around;  
        gap: 1rem;
    }

    .editEmailHader{
        padding-bottom: 14px;
    }

    .radioTextHeader{
        gap: 1rem;
    }

    .radioText > p{
        font-size: 14px;
    }
}

@media screen and (max-width: 450px){
    .courseAndCompletement{
        font-size: 14px;
    }

    .courseTitle{
        font-size: 13px;
    }

    .courseSyllabus{
        font-size: 12px;
    }

    .editPer{
        font-size: 12px;
    }
}

@media screen and (max-width: 480px){
    .borderProfile{
        width: 180px;
        height: 180px;
    }

    .profilePic{
        font-size: 148px;
    }

    .editNameText {
        width: 100%;
    }

    .editEmailAndName{
        display: flex;
        flex-direction: column;
    }

    .emailNamePoint{
        padding-bottom: 18px;
    }

    .editChoose{
        padding: 20px 20px;
    }

    .radioTextHeader{
        display: flex;
        flex-direction: column;
        padding: 0 16px;
    }

    .editBtn{
        flex-direction: row;
    }

    .editUpAndRe{
        font-size: .7rem;
        padding: .8rem 1.2rem;
        width: 100%;
    }

    .studayingHeader{
        font-size: 23px;
    }

    .lines{
        max-width: 5.4rem;
    }
}

@media screen and (max-width: 300px){
    .editMainTable{
        max-width: 100%;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li{
    list-style: none;
}

.navHeader{
    background-color:  #E5E7EB;
	box-shadow: 5px 3px 7px #6B7280;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 0 20px;
	position: fixed;
	width: 100%;
	z-index: 999;
}


/* new slider */

#hamburger{
	width: 20px;
    background-color: inherit;
    color: rgb(8, 9, 94);
    font-size: 24px;
}

#nav {
	margin-left: -320px;
}

.overlay{
	width: 320px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #BFDBFE;
    z-index: 1;
    transition: 0.5s;
    overflow-x: hidden;
    color: #5e646e;
    transition-timing-function: ease;
}

.logoimg1 {
	width: 180px;
	display: flex;
}

.navContainer{
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
	align-items: center;
	width: 100%;
    height: 70px;
    background-color: #fff;
    box-shadow: 5px 3px 7px #4b4a4a;
}

#closebtn{
	font-size: 48px;
    font-weight: 800;
    color: steelblue;
}

.overlayPM{
	width: 100%;
    max-width: 320px;
    height: 65px;
    background-color: #6d96c7;
    border-radius: 2px;
    padding: 5px 10px;
    margin: 1px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    border-radius: 15px;
    align-items: center;
}

#nav-content{
	padding: 10px 12px;
}

#overlayItemsContainer{
    margin-top: 10px;
}

.overlayItems{
	max-width: 300px;
    cursor: pointer;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 5px;
    border: 1px solid #DBEAFE;
    background-color: #EFF6FF;
    border-radius: 16px;
    margin-bottom: 8px;
    box-shadow: 2px 1px 5px #7b7f86;
}

.navIcon{
	width: 36px;
    height: auto;
    display: inline;
    margin-right: 20px;
}

.overlayItems span {
    font-size: 18px;
    font-weight: 600;
}

#avatar {
    width: 50px;
}

.avatarpp {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    border: 3px solid rgb(248, 212, 6);
}

#profileText {
    color: #fff;
    font-weight: 600;
    width: 80%;
}
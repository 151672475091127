@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.paymentChoose{
    padding-top: 80px;
    max-width: 100vw;
    margin: 0 auto;
    scroll-behavior: auto;
    overflow-y: scroll;
}

.paymentChoose h1{
    text-align: center;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--main-font-color-dark);
}

.paymentChoose p{
    padding: 14px 5px 10px 5px;
    text-align: justify;
    font-size: 16px;    
    color: var(--clr-grey-3);
}

.paymentInput, select {
    width: 100%;
    padding: 8px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.paymentLogo{
    background: #fff;
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    padding-top: 5px;
    padding-bottom: 8px;
}

.paymentLogoImg{
    width: 100px;
    padding: 10px 18px 10px 18px;
    /* object-fit: cover; */
}

.paymentBottom{
    position: fixed;
    bottom: 0;
    background: #fff;
}

.paymentHeader{
    text-align: center;
    color: var(--main-font-color-dark);
    font-family: var(--roboto-font);
    font-size: 20px;
    letter-spacing: 2px;    
    font-weight: 600;
    z-index: 2;
}

.paymentBtn{
    background: #2ECC71;    
    width: 100%;
    padding: 10px 0;
    color: var(--background-color);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.paymentBtn:focus {     
    background-color: #0ef26d;    
    outline: none;
}
.txt-nophone {
    border: 1px solid red;
}
.txt-nophone:focus, .txt-nophone:focus-visible, .txt-nophone:focus-within {
    border: 1px solid red !important;
}
img.active.paymentLogoImg {
    border: 3px solid #2ECC71;
    border-radius: 10%;
}
.paymentName{
    padding: 0px 10px 0px 10px;
    text-align: center;
}

#popup {
    position:fixed;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.75);
    z-index: 2;
}

#popup .popup-content {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:90vw;
    background:#eee;
}

.popupNameAndNumber{
    padding:10px 20px;
}

.popupNameAndNumber p{
    padding-bottom: 18px;
}

.popupConBtn{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.popupBtn{
    background: #337AB7;
    color: var(--background-color);
    padding: 7px;
    border-radius: 5px;
}

.popupBtn:active{
    color: #fff;
    text-decoration: none;
}

.popup-content h1{
    background: #2ECC71;
    width: 100%;
    padding: 10px 0;
    color: var(--background-color);
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
}

.checkIcon{
    color: var(--background-color);
    font-size: 32px;
    margin: 10px 0;
}

.checkBackground{
    background: #2ECC71;
    text-align: center;
}

.successPayment{
    text-align: center;
    font-weight: 600;
    margin: 30px 0;
}

@media screen and (min-width: 770px){
    .paymentBtn{
        padding: 28px 0;
        font-size: 26px;
    }

    .paymentName label{
        font-size: 26px;
    }

    .paymentInput, select {
        padding: 20px 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 22px;
    }

    .paymentHeader{
        font-size: 26px;
    }
}

@media screen and (max-width: 320px){
    .paymentName{
        padding-bottom: 10px;
    }
}

@media screen and (max-height: 720px) {
    .paymentChoose{
        height: 470px;
    }
}

@media screen and (max-height: 568px) {
    .paymentChoose{
        height: 355px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.textC{
    padding-top: 100px;
    text-align: center;
    color: var(--main-font-color-dark);
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
}

.cardC{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: .5rem;
    margin: 15px 10px;
}

.cardsC{
    border: 1px solid #d9dee1;
    width: 100%;
    padding: 8px;
    background: #fff;
}

.imgAndDes{
    position: relative;
}

.imgC{
    border-radius: 5px;
}

.desC{
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    padding: 0 8px;
    border-top-right-radius: 10px;
    font-size: 12px;
    font-weight: bold;
}

.textAndBtnC{
    display: flex;
    justify-content: space-between;
    padding: 10px 12px 25px 12px;
    align-items: baseline;
    font-family: var(--roboto-font);
}

.fontAwesomeC{
    border-top: 1px solid #d9dee1;
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
}

.backgroundColorD{
    background-color: var(--main-font-color-dark);
    padding-top: 70px;
}

.careersLink{
    color: var(--background-color);
    font-family: var(--roboto-font);
    font-size: 1rem;
}

.careersDetail{
    padding: 10px 20px;
} 

.careersName{
    color: var(--background-color);
    margin-bottom: 8px;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    margin: 18px auto;
    font-family: var(--roboto-font);
}

.car-btn{
    margin-top: 1rem;
}

.careers-btn{
    font-size: .8rem;
    letter-spacing: 1px;
    background-color: #1c7bc9;
    padding: .5rem .6rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;
}

.careersBorder{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.careersCourse{
    border: 1px solid green;
    width: 100px;
    text-align: center;
    border-radius: 10px;
    padding: 7px 10px;
    background: #7CFC00;
    color: var(--clr-grey-3);
    letter-spacing: 1px;
    font-weight: bold;
}

.careersDetailTitle{
    font-size: 18px;
    font-weight: bold;
}

.careersDes{
    margin: 15px 0;
}

@media screen and (min-width: 540px){
    .cardC{
        grid-template-columns: 1fr 1fr;
    }
}
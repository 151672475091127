@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body{
    background-color: #FCFAF2;
}

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.main-container{
    max-width: 1024px;
	margin: 0px auto;
    display: flex;
    justify-content: center;
    padding-top: 100px;
}

.cards{
    display: grid;
	grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
    padding: 0 12px;
}

.card{
    border: 1px solid #d9dee1;
    width: 100%;
    padding: 8px;
}

.cardContainer{
    border: 1px solid #d9dee1;
}

.card-img{
    position: relative;
}

.classImg{
    border-radius: 0;
}

.level{
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    padding: 0 8px;
    border-top-right-radius: 10px;
}

.level p{
    font-family: var(--roboto-font);
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 13px;
}

.title-open{
    display: flex;
    justify-content: space-between;
    padding: 10px 12px 35px 12px;
    align-items: center;
    font-family: var(--roboto-font);
}

.dashboardTitle{
    font-size: 16px;
    font-family: var(--roboto-font);
}

.fontAwesome{
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    color: var(--main-font-color-dark);
    font-size: .9rem;
}

.fontAwesome p{
    font-family: var(--roboto-font);
    font-size: .8rem;
}

.fontAwesomeContainer{
    border-top: 1px solid #d9dee1;
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
}

.open-btn{
    font-size: .8rem;
    letter-spacing: 1px;
    background-color: #1c7bc9;
    padding: .3rem .4rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;
}

.open-btn:hover,
.open-btn:focus{
    color: var(--main-font-color-dark);
    background-color: var(--background-color);
}

@media screen and (max-width: 768px){
    .cards{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 640px) {
    .cards{
        display: grid;
        grid-template-columns: 1fr;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.center {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.popup {
    min-width: 85vw;
    padding:8px;
    background:#fff;
    border-radius:10px;
    box-sizing:border-box;
    z-index:2;
    border: 1px solid var(--main-font-color-dark);
}

.popup .icon {
    margin:5px 0px;
    width:50px;
    height:50px;
    border:2px solid #34f234;
    display:inline-block;
    align-items: center;
    border-radius:50%;
    line-height:60px;
}

.popup .icon .fa {
    font-size:30px;
    color:#34f234;
} 

.popup .title {
    margin:16px 0px;
    display: flex;
    justify-content: center;
    font-family: var(--roboto-font);
    font-size: 18px;
    color: var(--main-font-color-dark);
    font-weight: 600;
}

.popup .description {
    color:var(--clr-grey-3);
    font-size: 15px;
    margin-bottom: 13px;
    text-align: initial;
    padding: 0 8px;
    font-family: var(--roboto-font);
    font-size: 14px;
}

.answerOnceBtn{
    display: flex;
    justify-content:flex-end;
    gap: 1rem;
}

.answerOnceLeft{
    font-size: .8rem;
    letter-spacing: 1px;
    background-color: #1c7bc9;
    padding: .3rem .4rem;
    color: var(--background-color);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    background-image: linear-gradient(#60A5FA, #2563EB, #60A5FA);
    border: 1px solid #2563EB;
}

.answerOnceRight{
    font-size: .8rem;
    padding: .4rem .5rem;
    background-color: var(--main-font-color-dark);
    color: var(--background-color);
    border-radius: .4rem;
    transition: .5s;
    font-weight: 600;
    border: 1px solid var(--main-font-color-dark);
    font-family: var(--roboto-font);
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-font-color-dark: #2B3648;
    --secondary-font-color: #CE5E5D;
    --background-color: #FCFAF2;
    --color-yellow: #FFC119;
    --color-gold: #C49C5F;
    --clr-grey-3: hsl(209, 34%, 30%);
    --roboto-font: 'Roboto', sans-serif;
    --color-light-blue: #006ba8;
}

.mark-header{
    padding-top: 100px;
}

.mark-header-title{
    font-size: 25px;
    text-align: center;
    color: var(--color-gold);
    font-family: 'Herr Von Muellerhoff', cursive;
}

.mark-separators{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
}

.mark-lines{
    width: 100%;
    max-width: 2.4rem;
    height: 0.1rem;
    background-color: var(--main-font-color-dark);
    position: relative;
}

.mark-line-lefts::before,
.mark-line-rights::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: .4rem solid transparent;
}

.mark-line-rights::before{
    border-right-color: var(--main-font-color-dark);
    right: 0;
}

.mark-line-lefts::before{
    border-left-color: var(--main-font-color-dark);
    left: 0;
} 

.mark-asterisks{
    font-size: 0.7rem;
    color: var(--main-font-color-dark);
    margin: 0 0.6rem;
}

.mark-main-table{
    max-width: 90%;
    margin: 25px auto;
}

.mark-table{
    width: 100%;
}

.mark-table-text{
    font-size: 14px;
    font-family: var(--roboto-font);
    letter-spacing: 1px;
    padding: 16px 5px;
    font-weight: 600;
}

.mark-result{
    padding: 18px 0 18px 6px;
    font-family: var(--roboto-font);
}

.mark-topic{
    font-family: var(--roboto-font);
    color: var(--main-font-color-dark);
    font-weight: 600;
    font-size: 14px;
}

.mark-syllabus{
    font-family: var(--roboto-font);
    color: gray;
    font-size: 13px;
}

.mark-data{
    font-size: 12px;
    font-style: italic;
}

.mark-result-info{
    align-items: center;
    display: grid;
    text-align: center;
    grid-gap: 0;
    gap: 0;
}

.mark-per{
    font-size: 9px;
}

.mark-result-info .progress{
    width: 100px;
    max-width: 100%;
    margin: auto 10px;
}

@media screen and (min-width: 700px){
    .mark-header-title{
        font-size: 50px;
    }

    .mark-lines{
        height: 0.3rem;
        max-width: 4.4rem;
    }

    .mark-line-lefts::before,
    .mark-line-rights::before{
        border: -1.6rem solid transparent;
    }

    .mark-asterisks{
        font-size: 0.9rem;
    }

    .mark-table-text{
        font-size: 26px;
    }

    .mark-topic{
        font-size: 24px;
    }

    .mark-syllabus{
        font-size: 22px;
    }

    .mark-data{
        font-size: 20px;
    }

    .mark-number{
        font-size: 22px;
    }

    .mark-result-info .progress{
        height: 25px;
    }

    .mark-per{
        font-size: 18px;
    }
}

@media screen and (min-width: 400px){
    .mark-result-info{
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    }
}
.navbar-brand {
  color: white;
  font-weight: 600;
  font-size: 28px;
  margin-left: 5px;
}
.navbar-link {
  color: white;
  font-size: 20px;
}
.navbar-item {
  list-style: none;
}
.login-item {
  position: absolute;
  right: 70px;
}

.register-header {
  margin-top: 30px;
  color: #007bff;
  margin-bottom: 30px;
}
.register-div {
  margin-left: 45px;
}
.form-label {
  font-size: 13px;
  font-weight: 600;
}
.custom-form-input {
  border-radius: 5px;
  border: 1px solid lightgray;
  height: 35px;
  width: 70%;
  margin-bottom: 30px;
}
.custom-btn {
  background-color: #007bff;
  color: whitesmoke;
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px;
}
.center {
  text-align: center;
}

.user-header {
  color: #007bff;
  text-align: center;
  margin-top: 20px;
}
